import React from 'react';

function ProgressBar({ totalSpace, usedSpace }) {
  const freeSpacePercent = (usedSpace / totalSpace) * 100;

  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <div style={{ ...styles.progressBar, width: `${freeSpacePercent}%` }} />
      </div>
      <div style={styles.labels}>
        <span>
          {usedSpace} из {totalSpace} использовано
        </span>
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    position: 'relative',
    top: '0px',
    marginLeft: '400px',
    width: '20%',
  },
  container: {
    top: '10px',
    position: 'absolute',
    width: '100%',
    height: '10px',
    backgroundColor: '#e0e0e0',
    borderRadius: '8px',
    padding: '5px',
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
  },
  progressBar: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    height: '10px',
    backgroundColor: '#76c7c0',
    borderRadius: '5px',
    transition: 'width 0.3s ease',
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '10px',
    color: '#555',
    marginTop: '25px', // Добавлен отступ для размещения текста под полосой
  },
};

export default ProgressBar;
