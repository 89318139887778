import axios from 'axios'
import { addFile, deleteFileAction, setFiles } from "../reducers/fileReducer";
import { addUploadFile, changeUploadFile, showUploader } from '../reducers/uploadReducer';
import { hideLoader, showLoader } from '../reducers/appReducer';
import { API_URL } from '../config'
import { deleteLink, setLinks, addLinkAction } from '../reducers/linksReducer';

export function getFiles(dirId, sort) {
    return async dispatch => {
        try {
            dispatch(showLoader())
            let url = `${API_URL}api/files`
            if (dirId) {
                url = `${API_URL}api/files?parent=${dirId}`
            }
            if (sort) {
                url = `${API_URL}api/files?sort=${sort}`
            }
            if (dirId && sort) {
                url = `${API_URL}api/files?parent=${dirId}&sort=${sort}`
            }
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            dispatch(setFiles(response.data))
        } catch (e) {
            alert(e.response.data.message)
            if (e.response.status === 401) {
                window.location.href = '/login'
            }
        } finally {
            dispatch(hideLoader())
        }
    }
}


export function getLinks(fileID) {
    return async dispatch => {
        try {
            const url = `${API_URL}api/files/links?fileId=${fileID}`
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            dispatch(setLinks(response.data))
        } catch (e) {
            alert(e.response.data.message)
        }
    }
}

export function createDir(dirId, name) {
    return async dispatch => {
        try {
            const response = await axios.post(`${API_URL}api/files`, {
                name,
                parent: dirId,
                type: 'dir'
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            dispatch(addFile(response.data))
        } catch (e) {
            alert(e.response.data.message)
        }
    }
}


export function addLink(linkName, link, fileId) {
    return async dispatch => {
        try {
            const formData = new FormData()
            formData.append('linkName', linkName)
            formData.append('link', link)
            formData.append('type', 'add')
            formData.append('fileId', fileId)
            await axios.post(`${API_URL}api/files/change`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            dispatch(addLinkAction({ name: linkName, content: link }))
            console.log(`add link done`)
        } catch (e) {
            console.log('Error add link')
            alert(e.response.data.message)
        }
    }
}


export function changeLink(linkNameOld, linkName, link, fileID) {
    return async dispatch => {
        try {
            const formData = new FormData()
            formData.append('linkName', linkName)
            formData.append('link', link)
            formData.append('linkNameOld', linkNameOld)
            formData.append('type', 'change')
            formData.append('fileId', fileID)
            const response = await axios.post(`${API_URL}api/files/change`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            dispatch(setLinks(response.data))
            console.log(`change link done`)
        } catch (e) {
            console.log('Error change link')
            alert(e.response.data.message)
        }
    }
}

export function uploadFile(file, dirId) {
    return async dispatch => {
        try {
            const formData = new FormData()
            formData.append('file', file)
            if (dirId) {
                formData.append('parent', dirId)
            }
            const uploadFile = { name: String(file.name), progress: 0, id: Date.now() }
            dispatch(showUploader())
            dispatch(addUploadFile(uploadFile))
            const response = await axios.post(`${API_URL}api/files/upload`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                onUploadProgress: progressEvent => {
                    const totalLength = progressEvent.event.lengthComputable ? progressEvent.event.total : progressEvent.event.target.getResponseHeader('content-length') || progressEvent.event.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength) {
                        uploadFile.progress = Math.round((progressEvent.loaded * 100) / totalLength)
                        dispatch(changeUploadFile(uploadFile))
                    }
                }
            });
            dispatch(addFile(response.data))
        } catch (e) {
            alert(e?.response?.data?.message)
        }
    }
}



export async function downloadFile(file) {
    const response = await fetch(`${API_URL}api/files/download?id=${file._id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    if (response.status === 200) {
        const blob = await response.blob() // Бинарные данные
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
}


export function deleteFile(file, userId) {
    return async dispatch => {
        try {
            const response = await axios.delete(`${API_URL}api/files?id=${file._id}&userId=${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            dispatch(deleteFileAction(file._id))
            alert(response.data.message)
        } catch (e) {
            alert(e?.response?.data?.message)
        }
    }
}


export function deleteFileLink(link, fileId) {
    return async dispatch => {
        try {
            const formData = new FormData()
            formData.append('link', JSON.stringify(link))
            formData.append('fileId', fileId)
            await axios.post(`${API_URL}api/files/change`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            dispatch(deleteLink(link.name))
        } catch (e) {
            alert(e?.response?.data?.message)
        }
    }
}


export function searchFiles(search) {
    return async dispatch => {
        try {
            const response = await axios.get(`${API_URL}api/files/search?search=${search}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            dispatch(setFiles(response.data))
            // alert(response.data.message)
        } catch (e) {
            alert(e?.response?.data?.message)
        } finally {
            dispatch(hideLoader())
        }
    }
}