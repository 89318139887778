import React from 'react';
import './file.css';
import dirLogo from '../../../../assets/img/dir.svg';
import fileLogo from '../../../../assets/img/file.svg';
import { useSelector, useDispatch } from 'react-redux';
import { pushToStack, setCurrentDir, setPopupDisplay } from '../../../../reducers/fileReducer';
import { deleteFile, downloadFile, getLinks } from '../../../../actions/file';
import sizeFormat from '../../../../utils/sizeFormat';
import { showLinks, setId } from '../../../../reducers/linksReducer';
import { auth } from '../../../../actions/user';

const File = ({ file }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.currentUser.id);
  const currentDir = useSelector((state) => state.files.currentDir);
  const fileView = useSelector((state) => state.files.view);

  function openDirHandler() {
    dispatch(auth());
    if (file.type === 'dir') {
      dispatch(pushToStack(currentDir));
      dispatch(setCurrentDir(file._id));
    }
  }

  function downloadClickHandler(e) {
    dispatch(auth());
    e.stopPropagation();
    downloadFile(file);
  }

  function deleteClickHandler(e) {
    dispatch(auth());
    e.stopPropagation();
    dispatch(deleteFile(file, userId));
  }

  function openFileLinks(e) {
    dispatch(auth());
    e.stopPropagation();
    dispatch(showLinks());
    dispatch(getLinks(file._id));
    dispatch(setId(file._id));
  }

  if (fileView === 'list') {
    return (
      <div className="file" onClick={() => openDirHandler(file)}>
        <img src={file.type === 'dir' ? dirLogo : fileLogo} alt="" className="file__img" />
        <div className="file__name">{file.name.slice(0, 30)}</div>
        <div className="file__date">{file?.date?.slice(0, 10)}</div>
        <div className="file__size">{sizeFormat(file.size)}</div>
        {file.type !== 'dir' && (
          <button onClick={(e) => downloadClickHandler(e)} className="file__btn file__download">
            download
          </button>
        )}
        <button onClick={(e) => deleteClickHandler(e)} className="file__btn file__delete">
          delete
        </button>
        {file.type === 'txt' && (
          <button onClick={(e) => openFileLinks(e)} className="file__btn file__open">
            open
          </button>
        )}
      </div>
    );
  }
  if (fileView === 'plate') {
    return (
      <div className="file-plate" onClick={() => openDirHandler(file)}>
        <img src={file.type === 'dir' ? dirLogo : fileLogo} alt="" className="file-plate__img" />
        <div className="file-plate__name">{file.name}</div>
        <div className="file-plate__btns">
          {file.type !== 'dir' && (
            <button onClick={(e) => downloadClickHandler(e)} className="file-plate__btn file-plate__download">
              download
            </button>
          )}
          <button onClick={(e) => deleteClickHandler(e)} className="file-plate__btn file-plate__delete">
            delete
          </button>
        </div>
      </div>
    );
  }
};

export default File;
